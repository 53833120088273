import React, { useState, useEffect } from 'react';
import './App.css';

function VideoViewer() {
  const [videos, setVideos] = useState([]);  // All video sources fetched from JSON file
  const [mainVideo, setMainVideo] = useState(null);  // Main video to display
  const [filterCount, setFilterCount] = useState(4);  // Number of videos in thumbnail view

  useEffect(() => {
    // Fetch video list from the JSON file
    fetch('/videos/videos.json')
      .then(response => response.json())
      .then(data => {
        setVideos(data.map(video => `/videos/${video}`)); // Create full path for each video
        if (data.length > 0) {
          setMainVideo(`/videos/${data[0]}`);  // Set the first video as default in the main view
        }
      })
      .catch(error => console.error('Error fetching video files:', error));
  }, []);

  const handleThumbnailClick = (video) => setMainVideo(video);

  const handleFilterChange = (e) => setFilterCount(Number(e.target.value));

  return (
    <div className="container">
      {/* Left column: Main video */}
      <div className="main-video-column">
        {mainVideo && (
          <div className="main-video">
            <video src={mainVideo} controls autoPlay />
          </div>
        )}
      </div>

      {/* Right column: Thumbnails and filter */}
      <div className="thumbnail-column">
        <div className="filter-controls">
          <label htmlFor="videoFilter">Number of Thumbnails: </label>
          <select id="videoFilter" value={filterCount} onChange={handleFilterChange}>
            {[4, 6, 8, 10, 12, 14, 16, 18, 20, 24, 28, 32, 64, 128].map((count) => (
              <option key={count} value={count}>{count}</option>
            ))}
          </select>
        </div>

        <div className="thumbnail-grid">
          {videos.slice(0, filterCount).map((videoSrc, index) => (
            <div key={index} className="thumbnail">
              <video src={videoSrc} onClick={() => handleThumbnailClick(videoSrc)} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default VideoViewer;
